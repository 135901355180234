import React, { useEffect } from "react";

// router
import RootRouter from "./Router.js";
import { BrowserRouter as Router } from "react-router-dom";
// import { HashRouter as Router } from "react-router-dom";

// cookie
import { CookiesProvider } from "react-cookie";

import axios from "axios";
import { getCookie } from "./common/session";
import { extendSessionOnMouseMove } from "./common/session";

axios.defaults.baseURL = process.env.REACT_APP_API_ADDRESS;
// 重新整理後 axios 參數會不見，這時需檢查 cookie 有無 session。
axios.defaults.headers.common["X-RenZheng"] = getCookie("session") ?? "";
console.log('axios.defaults.headers.common["X-RenZheng"] :', axios.defaults.headers.common["X-RenZheng"]);

export default function App() {
  useEffect(() => {
    extendSessionOnMouseMove();
  }, []);

  return (
    <CookiesProvider>
      <Router>
        <RootRouter />
      </Router>
    </CookiesProvider>
  );
}
