import React, { useState, useEffect } from "react";

// core
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// icons
import MenuIcon from "@material-ui/icons/Menu";

import axios from "axios";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

import { handleClearLoginCookies, getSessionExpiry, extendSessionOnMouseMove, handleExpiredCookies } from "../common/session.js";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFFFFF",
    },
  })
);

export default function OPHeader(props) {
  const history = useHistory();
  // eslint-disable-next-line
  const [cookies] = useCookies(["session", "op_name"]);
  const [user, setUser] = useState("");
  const [sessionTimeLeft, setSessionTimeLeft] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    setUser(cookies.op_name);
    
    const removeMouseMoveListener = extendSessionOnMouseMove();

    const interval = setInterval(() => {
      const sessionExpiry = getSessionExpiry();
      if (sessionExpiry) {
        const timeLeft = sessionExpiry - Date.now();
        setSessionTimeLeft(Math.max(timeLeft, 0));
        if (timeLeft <= 0) {
          handleExpiredCookies();
          logout();
        }
      } else {
        setSessionTimeLeft(0);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      if (removeMouseMoveListener) {
        removeMouseMoveListener();
      }
    };

  }, [cookies.op_name]);

  const logout = () => {
    handleClearLoginCookies()
    axios
      .post("/api/v0/logout", {
        session: cookies.session,
      })
      .then(function (response) {
        history.replace("/backoffice/Login");
      })
      .catch(function (error) {
        console.log(error);
        history.replace("/backoffice/Login");
      });
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} 分 ${seconds} 秒`;
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton edge="start" onClick={props.handleDrawerOpen}>
          <MenuIcon />
        </IconButton>

        <a href="/backoffice/Orders">
          <Button>首頁</Button>
        </a>
        <Typography>Version: {process.env.REACT_APP_MODE}</Typography>               
        <div style={{ color: sessionTimeLeft <= 30000 ? "red" : "black", marginLeft: "auto", float: "right" }}>
          {user} 您好  系統登入可用剩餘: {formatTime(sessionTimeLeft)}<Button onClick={logout}>登出</Button>          
        </div>
      </Toolbar>
    </AppBar>
  );
}
